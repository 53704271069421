<template>
    <div class="attendees">
      <div class="title">
        签到验票
      </div>
      <div class="content">
        <ul>
          <li class="content-item">
            <div class="sort">01</div>
            <div class="paper-sign">
              <div class="sign-title">
                <span>纸质签到</span>
                <div class="back-list" @click="backList">
                  <img src="../../assets/images/G/backIcon.png" alt="">
                  <span>返回发起列表</span>
                </div>
              </div>
              <p>导出名单管理的所有有效报名信息，打印成纸质签到表，现场使用。</p>
              <div class="btn" @click="ticket">
                <button>进入名单管理</button>
              </div>
            </div>
          </li>
          <li class="content-item" v-show="true">
            <div class="sort">02</div>
            <div class="code-sign">
              <div class="sign-title">
                <span>扫码签到</span>
              </div>
              <p>用手机打开微信，第一次扫码需要先登录Cityplus账号,登录后即可扫二维码验证。</p>
              <div class="code-list">
                <el-col :span="24">
                  <img src="../../assets/images/G/wx-code-lc.png" alt="">
                </el-col>
<!--                <el-col :span="20">-->
<!--                  <ul>-->
<!--                    <li v-for="(item,index) in adminList" class="code-item" :key="index">-->
<!--                      管理员{{index+1}}: <span>赵丽颖</span>-->
<!--                      <div class="cancel">取消</div>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </el-col>-->
              </div>
            </div>
          </li>
          <li class="content-item">
            <div class="sort">03</div>
            <div class="verCode-sign">
              <div class="sign-title">
                <span>验证码签到</span>
              </div>
              <p>扫码进入手机端页面，输入入场编码，进行操作验证。</p>
              <div class="verCode-content">
                <el-col :span="4">
                  <!-- <img width="100%" src="../../assets/images/Login/code.png" alt=""> -->
                  <div id="codeqr" style="width: 100%;"></div>
                </el-col>
                <el-col :span="20">
                  <div class="right">
                    <p class="sign-link">签到页面链接：{{copyhref}}</p>
                    <div class="verCode-btn">
                      <button v-clipboard:copy="copyhref" v-clipboard:success="onCopy" v-clipboard:error="onError">复制链接</button>
                      <!-- <button>进入签到页面</button> -->
                    </div>
                  </div>
                </el-col>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
  name: 'attendees',
  data () {
    return {
      adminList: [
        {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
      ],
      copyhref: window.location.protocol + '//' + window.location.host + window.location.pathname + '#/code'
    }
  },
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
      this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        this.$router.push('/Login')
      })
    } else {
      this.getcode('codeqr', this.copyhref)
      if (JSON.stringify(this.$route.params) != '{}') {
        let data = JSON.stringify(this.$route.params.item)
        localStorage.setItem('examine', data)
      }
    }
  },
  methods: {
    getcode (id, h) {
      new QRCode(id, {
        width: 150, // 设置宽度，单位像素
        height: 150, // 设置高度，单位像素
        text: h // 设置二维码内容或跳转地址
      })
    },
    ticket(){
      this.$router.push('/user/signnote')
    },
    backList () {
      this.$router.push('/user/eventadmin')
    },
    onCopy(){
      this.$message({
        showClose: true,
        message: '复制成功，请在移动端打开验票',
        type: 'success',
        center: true
      });
    },
    onError(){
      this.$message({
        showClose: true,
        message: '复制失败',
        type: 'error',
        center: true
      });
    }
  }
}
</script>

<style scoped lang="stylus">
.attendees
  .title
    font-size 0.44rem
    color #A5CD39
    text-align left
    padding  0.3rem 0 0.3rem 0.1rem
    border-bottom 1px solid #C9CACA
  .content
    ul
      .content-item
        width 100%
        position relative
        padding .48rem 0.8rem
        text-align left
        border-bottom  1px dashed #a5cd39
        .sort
          position absolute
          top 0.333rem
          left 5px
          font-size 0.444rem
          color #A5CD39
        .paper-sign
          .sign-title
            span
              font-size 0.3rem
              color #373737
            .back-list
              float right
              display flex
              align-items center
              cursor pointer
              span
                font-size 0.25rem
                color #8d8d8d
                margin-top 2px
                margin-left 0.13rem
          p
            font-size 0.25rem
            color #373737
            margin-top 0.2rem
          .btn
            margin-top 0.42rem
            button
              padding 0.15rem 0.65rem
              background #A5CD39
              color #fff
              font-size 0.25rem
              border none
              cursor pointer
        .code-sign
          .sign-title
            span
              font-size 0.3rem
              color #373737
          p
            font-size 0.25rem
            color #373737
            margin-top 0.2rem
          .code-list
            display: flex
            margin-top 0.5rem
            ul
              padding 0 0.8rem
              column-count 2
              .code-item
                width 3.5rem
                font-size 0.25rem
                color #373737
                padding  0.12rem 0
              .cancel
                display inline-block
                cursor pointer
                float right
                color #8d8d8d

        .verCode-sign
          .sign-title
            span
              font-size 0.3rem
              color #373737
          p
            font-size 0.25rem
            color #373737
            margin-top 0.2rem
          .verCode-content
            display flex
            align-items center
            margin-top: 0.5rem;
            .right
              padding: 0 0.8rem;
            .sign-link
              font-size 0.25rem
              color #8D8D8D
            .verCode-btn
              margin-top 0.18rem
              button
                width 3rem
                font-size 0.25rem
                padding 0.2rem 0
                cursor pointer
                &:nth-child(1)
                  background #fff
                  border 1px solid #D1D1D1
                  margin-right 0.2rem
                &:nth-child(2)
                  background #A5CD39
                  border 1px solid #A5CD39
</style>
